<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4">
          <CCardGroup>
            <CCard>
              <CCardBody>
                <CForm id="login-form" method="post" @submit.prevent="login">
                  <h2 class="text-center mb-3">Please Login</h2>
                  <CInput
                    name="username"
                    placeholder="Username"
                    autocomplete="username"
                    v-model="input.username"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>

                  <CInput
                    name="password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="input.password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CButton type="submit" color="primary" class="px-4 btn-block" :disabled="isSubmit">{{isSubmit?'Loading...':'Login'}}</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import AuthService from '@/services/authService'

import firebase from 'firebase/app'
import 'firebase/auth'

import Swal from 'sweetalert2'

export default {
  name: 'Login',
  data() {
    return {
      isSubmit: false,
      input: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    login(e) {

      this.isSubmit = true
      try {

        if(!this.input.username || !this.input.password) {
          throw {
            title: '',
            message: 'Please enter username and password'
          }
        }

        if(!process.env.VUE_APP_USERNAMES.split(',').includes(this.input.username)) {
          throw {
            title: 'Invalid',
            message: 'Uername or password incorrect.'
          }
        }

        const email = `${this.input.username}@${process.env.VUE_APP_DOMAIN}`
        firebase
        .auth()
        .signInWithEmailAndPassword(email, this.input.password)
        .then(async (data) => {
          await AuthService.saveLogin()
          console.log('login success')
          this.$router.push({name: 'Home'})
        })
        .catch(err => {
          this.isSubmit = false
          Swal.fire({
            title: err?.title,
            text: err?.message,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })

      }
      catch(e) {
        this.isSubmit = false
        Swal.fire({
          title: e.title,
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
      
    }
  },
  mounted() {
    console.log('mounted')
    AuthService.getToken().then((res)=>{
      console.log(res)
    })
  }
}
</script>
<style lang="scss" scoped>
.c-app {
  background-color: #01579B !important;
}
</style>
